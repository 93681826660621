import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import SurveillanceImageGridItem from './SurveillanceImageGridItem';
import CommandPanel from './CommandPanel'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 'auto',
    height: 'auto',
  },
}));




export default function SurveillanceImagesGrid(props) {
  const classes = useStyles();
  let imageData = [];

  if(props && props.images){
    imageData = props.images;
  }
  
  return (

    

    <div className={classes.root} style={{ width:"100%"}}>
      <CommandPanel style={{ width:"100%", marginBottom:"8px"}}                  
        startAlarmOnClick={props.startAlarmOnClick}
        takePictureOnClick={props.takePictureOnClick}
        stopAlarmOnClick={props.stopAlarmOnClick}
        setDeviceConfigurationOnClick={props.setDeviceConfigurationOnClick}
        deviceStates={props.deviceStates}
        deviceConfiguration={props.selectedDeviceConfiguration}
        selectedDeviceConfiguration={props.selectedDeviceConfiguration}
        selectedDeviceConfigurationDocumentPath={props.selectedDeviceConfigurationDocumentPath}
        openDeviceConfigurationOnClick={props.openDeviceConfigurationOnClick} 
      />

      <GridList style={{ width:"100%", marginTop:"8px"}}  cellHeight={360} cellWidth={360} className={classes.gridList} cols={0}>
        {imageData.map((imageData) => (
          <GridListTile key={imageData.documentPath} cols={imageData.cols || 1}>
            <SurveillanceImageGridItem detectedObjects={imageData.detectedObjects} deleteImageOnClick={props.deleteImageOnClick} imageOnClick={props.imageOnClick} imageDocumentPath={imageData.documentPath} imageMarkedReference={imageData.imageMarkedReference} image={imageData.imageDownloadUrl} />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}