import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginBottom: '8px',
    overflow: 'scroll'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 20,
  },
  pos: {
    marginBottom: 6,
  },
  formControl: {
    margin: 0,
    marginTop: 4,
    marginBottom: 24,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: 2,
  },
  grow: {
    flexGrow: 1
  },
});

let deviceConfiguration = {};

function valuetext(value) {
  return '${value}';
}


export default function DeviceConfiguration(props) {
  const classes = useStyles();
  if(props && props.deviceConfiguration){
    deviceConfiguration = props.deviceConfiguration;
  }


  const [state, setState] = React.useState({
    deviceConfiguration: props.deviceConfiguration
  });

  const handleChange = (event) => {
    //console.log("MVA Event 2 "+event.target.name+" "+event.target.checked);
  
    
    for (const [index, value] of state.deviceConfiguration.detectionObjects.entries()) {
      //console.log("MVA Event 3 value "+JSON.stringify(value));
      //console.log("MVA Event 3 "+JSON.string)
      if(value.mdObject===event.target.name){
        console.log("MVA Event 3 - found! "+value.mdObject);
        value.selected = event.target.checked;
      }
    }
    setState({deviceConfiguration: deviceConfiguration});
  };
  
  const handleMotionDetectionModeChange = (event) =>{
    console.log("handleMotionDetectionModeChange : "+event.target.value);
    state.deviceConfiguration.motionDetectionMode = event.target.value;
  }

  const handleReceiverEmailChange = (event) =>{
    let receiverEmail = [];
    receiverEmail[0] = {receiverId:0, receiverEmail:event.target.value}
    state.deviceConfiguration.receivers = receiverEmail;
  }


  const handleSensitivityChange = (event, value) =>{
    console.log("sensitivity; "+value);
    state.deviceConfiguration.sensitivity = value;
  }

  const handleMotionDetectionChange = (event, value) =>{
    console.log("motion detection value; "+value);
    state.deviceConfiguration.motionSensitivity = value;
  }

  const getSensitivity2 = (props) => {
    console.log("MVA Props: "+JSON.stringify(props));
    if(props && props.deviceConfiguration && props.deviceConfiguration.sensitivity){
      console.log("MVA getSensitivity2: "+JSON.stringify(props.deviceConfiguration.sensitivity));
      return props.deviceConfiguration.sensitivity;
    }else{
      console.log("MVA getSensitivity2 -2: "+50);
      return 50;
    }
  }

  const getDefaultMotionDetectionMode = (props) => {
    if(props && props.deviceConfiguration && props.deviceConfiguration.motionDetectionMode){
      return props.deviceConfiguration.motionDetectionMode;
    }else{
      return "ONLY_ON_WHEN_DARK";
    }
  }

  const getMotionSensitivityValue = (props) => {
    if(props && props.deviceConfiguration){
      if(props.deviceConfiguration.motionSensitivity===0){
          return 0;
      }else if(props.deviceConfiguration.motionSensitivity){
        return props.deviceConfiguration.motionSensitivity;
      }
    }
    return 50;
  }


  const getDeviceName = (deviceConfiguration) =>{
    if(deviceConfiguration && deviceConfiguration.deviceName){
      return deviceConfiguration.deviceName;
    }
  }

  const handleDeviceNameChange = (event) =>{
    state.deviceConfiguration.deviceName = event.target.value;
  }

  const getReceiverEmail2 = (deviceConfiguration) =>{
    if(deviceConfiguration && deviceConfiguration.receivers && deviceConfiguration.receivers.length>0 && deviceConfiguration.receivers[0].receiverEmail){
      return deviceConfiguration.receivers[0].receiverEmail;
    }
  }




  const renderObjectsToDetect3 = (deviceConfiguration) => {
    //const [checked, setChecked] = React.useState(true);
    const items = []

    if(deviceConfiguration && deviceConfiguration.detectionObjects){
      //console.log("MVA Elements 1: "+JSON.stringify(deviceConfiguration))
      //console.log("MVA Elements: "+JSON.stringify(elements));
      //for(var i = 0; i < deviceConfiguration.detectionObjects.length; i++) {
      
      for (const [index, value] of deviceConfiguration.detectionObjects.entries()) {
        //let value = deviceConfiguration.detectionObjects[i];
        items.push(
          <FormControlLabel
              control={
              <Checkbox
                  checked={value.selected}
                  name={value.mdObject}
                  color="primary"
                  onChange={handleChange}
              />
          }
          label={value.mdObject}
          />
        )
      }
    }
    return items;
}


  //state = { counter: 0 };
  let setDeviceConfigurations = (e) => {
    e.stopPropagation();
    console.log("MVA Modify model here: "+JSON.stringify(props.deviceConfiguration));
    props.setDeviceConfigurationOnClick(props.deviceConfiguration);
    props.closeModal();
  }

  let takePicture = (e) => {
    e.stopPropagation();
    props.takePictureOnClick(props.deviceConfiguration);
  }

  let startAlarm = (e) => {
    e.stopPropagation();
    props.startAlarmOnClick(props.deviceConfiguration);
  }

  let deleteDeviceConfiguration = (e) => {
    e.stopPropagation();
    props.deleteDeviceConfigurationOnClick(props.deviceConfiguration);
  }

  let stopAlarm = (e) => {
    e.stopPropagation();
    props.stopAlarmOnClick(props.deviceConfiguration);
  }
  
  const renderTakePictureButton = (deviceStates, deviceConfiguration) => {
    if(deviceStates && deviceConfiguration){
        let documentPath = deviceConfiguration.documentPath;
        let deviceState = null;
        for(var i = 0; i < deviceStates.length; i++) {
            if(deviceStates[i].deviceConfigurationDocumentPath===documentPath){
                deviceState = (deviceStates[i]);
            }
        }
        if(deviceState&&deviceState.uiState){
            switch(deviceState.uiState) {
                case "UNARMED":
                case "STANDBY":  
                case "READY_ARMED":
                return(
                        <Button variant="outlined" onClick={takePicture}>
                            Take picture
                        </Button>
                    )
                default:
                    return (
                        <Button variant="outlined" disabled>
                            Take picture
                        </Button>
                    )
                }
                
            }
        }
    }

  const renderStartStopButton = (deviceStates, deviceConfiguration) => {
    if(deviceStates && deviceConfiguration){
        let documentPath = deviceConfiguration.documentPath;
        let deviceState = null;
        for(var i = 0; i < deviceStates.length; i++) {
            if(deviceStates[i].deviceConfigurationDocumentPath===documentPath){
                deviceState = (deviceStates[i]);
            }

        }
        if(deviceState&&deviceState.uiState){
            switch(deviceState.uiState) {
                case "UNARMED":
                case "STANDBY":  
                    return(
                        <Button variant="outlined" onClick={startAlarm}>
                            Start
                        </Button>
                    )
                case "READY_ARMED":
                case "ARMING_COUNTDOWN":    
                    return (
                        <Button variant="outlined" onClick={stopAlarm}>
                            Stop
                        </Button>
                    )
                default:
                    return (
                        <Button variant="outlined" disabled>
                            Offline
                        </Button>
                    )
            }
        }
    }
  }   


  return (
    <div style={{overflow:"scroll"}}>

      <Card className={classes.root} variant="outlined">
        <CardActions>
          {
            renderStartStopButton(props.deviceStates, props.deviceConfiguration)
          }
          {
            renderTakePictureButton(props.deviceStates, props.deviceConfiguration)
          }
          <div className={classes.grow} />
          <IconButton aria-label="delete" className={classes.margin} onClick={deleteDeviceConfiguration}>
              <DeleteIcon />
           </IconButton>

          </CardActions>

      </Card>

      <Card className={classes.root} variant="outlined">
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
              Objects to detect
            </Typography>
          {renderObjectsToDetect3(state.deviceConfiguration)}

          <Typography className={classes.title} color="textSecondary" gutterBottom>
              Confidence
            </Typography>
            <Slider
              defaultValue={getSensitivity2(state)}
              getAriaValueText={valuetext}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              onChange={handleSensitivityChange}
              step={1}
              marks
              min={10}
              max={100}
            />
            <Typography variant="body2" component="p">
              This value is the confidence that the answer is the right match for the given object. A higher value will give more accurate answers, but fewer alarms. Default is 50.
            </Typography>

        </CardContent>
      </Card>


      <Card className={classes.root} variant="outlined" style={{display: "none"}}>
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Receiver
          </Typography>
          <TextField fullWidth id="outlined-basic" label="Email" variant="outlined" onChange={handleReceiverEmailChange} defaultValue={getReceiverEmail2(state.deviceConfiguration)}/>
          <Typography variant="body2" component="p">
            The email which should receive a message when the alarm is triggered
          </Typography>
        </CardContent>
      </Card>



        <Card className={classes.root} variant="outlined">
        <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
              Motion Detection
            </Typography>


            <FormControl className={classes.formControl}>
              <Select
                native
                defaultValue={getDefaultMotionDetectionMode(state)}
                value={state.motionDetectionMode}
                onChange={handleMotionDetectionModeChange}
                inputProps={{
                  name: 'age',
                  id: 'age-native-simple',
                }}
              >
                <option value={"ONLY_ON_WHEN_DARK"}>Automatic (default)</option>
                <option value={"OFF"}>Off</option>
                <option value={"ALWAYS_ON"}>On</option>

              </Select>
            </FormControl>



          <Typography className={classes.title} color="textSecondary" gutterBottom>
              Motion detection sensitivity
            </Typography>
            <Slider
              defaultValue={getMotionSensitivityValue(state)}
              getAriaValueText={valuetext}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              onChange={handleMotionDetectionChange}
              step={1}
              marks
              min={0}
              max={100}
            />


            <Typography variant="body2" component="p">
            All movements your camera register e.g. doors opening, lights, clouds etc. Please note this consumes a lot of battery and can trigger a lot of false alarms
            </Typography>

        </CardContent>
      </Card>




      <Card className={classes.root} variant="outlined">
        <CardContent>
          <TextField fullWidth id="outlined-basic" label="Device name" variant="outlined" onChange={handleDeviceNameChange} defaultValue={getDeviceName(state.deviceConfiguration)}/>
          <Typography variant="body2" component="p">
            E.g. kitchen, living room etc
          </Typography>
        </CardContent>
      </Card>

        <Button variant="contained" onClick={setDeviceConfigurations}>
            Apply settings
        </Button>
    </div>

  );
}