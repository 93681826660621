import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';


const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 20,
    },
    button: {
      fontSize: 12,
    },
    pos: {
      marginBottom: 6,
    },
    avatar: {
        margin: 10,
      },
      bigAvatar: {
        width: 60,
        margin:10,
        height: 60,
      },
      
  });

export default function GlobalCommandPanel(props) {

    const classes = useStyles();
    
  

    let startAlarms = (e) => {
        e.stopPropagation();
        props.startAllAlarmsOnClick();
    }

    let stopAlarms = (e) => {
        e.stopPropagation();
        props.stopAllAlarmsOnClick();
    }

    let showAccountModal = (e) => {
      e.stopPropagation();
      props.showAccountModal();
  }

  return (
  
    <div>
        <Card className={classes.root} variant="outlined">
            <CardActions>
               <Avatar
                    alt="Avatard"
                    src={props.user.photoURL}
                    className={classes.bigAvatar}
                />
                <div style={{width:"100%"}}>
                  <div>{props.user.displayName}</div>
                  <div style={{fontSize:"0.7rem"}}>{props.user.email}</div>
                  </div>
                
                <Button variant="outlined" className={classes.button} style={{marginRight:10}} onClick={showAccountModal}>Account</Button>
            </CardActions>
        </Card>
        <Card className={classes.root} variant="outlined">
            <CardActions>
                <Button variant="outlined" onClick={startAlarms}>
                    Arm all devices
                </Button>
                <Button variant="outlined" onClick={stopAlarms}>
                    Disarm all device
                </Button>
            </CardActions>
        </Card>
    </div>
  )

}