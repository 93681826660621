import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Account from './components/account';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 600,
    maxHeight:850,
    overflow:"auto",
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #EEEEEE',
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function AccountModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);


  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Account 
        closeModal={props.closeModal.bind(this)}
        deleteAccount={props.deleteAccount}
        user = {props.user}
      />
    </div>
  );

  return (
    <div style={{position:"absolute", left:"0px"}}>
      <Modal
        open={props.open}
        onClose={props.closeModal.bind(this)}>
        {body}
      </Modal>
    </div>
  );
}