import React from "react";
import Card from '@material-ui/core/Card';
import { useState } from "react";
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
      minWidth: 275,
      marginBottom: '8px',
      overflow: 'scroll'
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 20,
      color:"#000000",
    },
    pos: {
      marginBottom: 6,
    },
    formControl: {
      margin: 0,
      marginTop: 4,
      marginBottom: 24,
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: 2,
    },
  });


export default function Account(props) {
    const classes = useStyles();
    const [deleteValidationValue, setDeleteValidationValue] = useState("");
    let deleteValueOnChange = (e) => {
          setDeleteValidationValue(e.target.value);
      };
    let deleteAccount = (e) => {
      if(deleteValidationValue.toLowerCase() === "delete"){
        e.stopPropagation();
        props.deleteAccount();
      }
    }

    let closeModal = (e) => {
      e.stopPropagation();
      props.closeModal();
    }

    return (
      <>
       <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography className={classes.title}>Welcome {props.user.displayName}</Typography><br />
            On this page, you can review the Terms and Conditions below or remove your account.
          
          </CardContent>
        </Card> 
        <Card className={classes.root} variant="outlined">
          <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                Terms and Conditions
                </Typography>
                <iframe src="TOC.html" width="100%" height="250px" frameborder="0" marginheight="0" marginwidth="0" title="Terms &amp; Conditions"></iframe>
             </CardContent>
         </Card> 
        <Card className={classes.root} variant="outlined">
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                Delete account
                </Typography>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                <TextField error={deleteValidationValue.toLowerCase() !== "delete" && deleteValidationValue!==""} helperText="Type 'delete' to delete your account and then press 'Delete Account'" id="deleteconfirm" label="Type 'DELETE'" onChange={deleteValueOnChange}/>
                <br /> <br />
                <Button variant="outlined" disabled={deleteValidationValue.toLowerCase() !== "delete"} onClick={deleteAccount}>Delete Account</Button>
                </Typography>

              </CardContent>
         </Card> 

         <Button variant="outlined" onClick={closeModal}>Close</Button>
       </>
  );
}