import React from "react";
import GooglePlayDownloadButton from "./components/googlePlayDownloadButton";
import CheckmarkItem from "./components/checkmarkitem";
import GuideBox from "./components/guideBox";
import InstallMobileTwoToneIcon from '@mui/icons-material/InstallMobileTwoTone';
import CameraIndoorTwoToneIcon from '@mui/icons-material/CameraIndoorTwoTone';
import PlayCircleFilledWhiteTwoToneIcon from '@mui/icons-material/PlayCircleFilledWhiteTwoTone';
import FeedbackTwoToneIcon from '@mui/icons-material/FeedbackTwoTone';
import SimpleModal from "./SimpleModal";


export default function LandingPage(props) {
    let login = (e) => {
        e.stopPropagation();
        props.loginWithGoogle();
    }

        return (
            <div className='text-center '>
    
            { /** New navbar test start start*/}
      
            <div className="h-screen flex flex-col">
              <header className="bg-gray-800 flex items-center justify-between px-4 py-2 ">
                <div className="flex items-center text-white">
     
                <span className="mx-1 font-semibold">Motion Detector</span>  <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">AI</span>
                  
                </div>
    
    
    
                <div className='float-right text-white '>
    
                  <button onClick={login} className="text-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800">
                    { /**<SignInGoogleBase buttonText="Login" googleLoginOnClick={this.loginWithGoogle.bind(this)} />*/}
                      Register
                    </button>
    
                    <button onClick={props.loginWithGoogle.bind(this)} className="text-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800 bg-white-tr bg-sky-500/50">
                    { /**<SignInGoogleBase buttonText="Login" googleLoginOnClick={this.loginWithGoogle.bind(this)} />*/}
                      Login to the console
                    </button>
                  </div>
    
              </header>
    
              <div className="flex flex-1 bg-gray-100 overflow-auto bg-gradient-to-r from-blue-100 to-blue-100">
              <div className='mx-auto'>
            { /** New navbar test start end */}
    
    
            { /** section Title start **/}
            <section className="mb-20 text-gray-800 text-center lg:text-center mr-48 ml-48">
              <h1 className="drop-shadow-md my-10 text-5xl md:text-6xl font-bold leading-tighter tracking-tighter mb-4 aos-init aos-animate" data-aos="zoom-y-out">Motion Detector <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">AI</span></h1>
              <h1 className="text-3xl my-0 md:4text-xl font-thin leading-tighter tracking-tighter mb-4 aos-init aos-animate drop-shadow-md">Our AI-Powered Motion Detector App Gives You Peace of Mind on a Budget.</h1>
              <p className="mx-10 my-16 mb-10 text-xl">
                With Motion Detector AI, you can use any Android device as a home security camera and with the help of artificial intelligence (AI), detect movements, persons, animals, cars and many other objects in the surrounding area and immediatly
                alert you with a notification on your phone if anything is detected.
                <br /><br />
                It's a cheap and reliable solution, that will give your old android device new super powers as a surveillance camera.
              </p>
            </section>
    
    
              { /** section title end **/}
    
            <section className="mb-14 text-gray-800">
              <div className="flex justify-center my-4">
    
              <GooglePlayDownloadButton />
                </div>
            </section>
    
    
              { /** Section 0 start **/}
           <section className="mb-20 text-gray-800">
    
              <h1 className="text-3xl my-0 md:4text-xl font-thin leading-tighter tracking-tighter mb-4 aos-init aos-animate">
                How to to get started in 4 easy steps
                </h1>
    
              <div className="flex justify-center my-8">
                
                  <GuideBox icon={<InstallMobileTwoToneIcon  sx={{ width: "50%", height: "50%", color: "#ccFFFFFF" }}/>} text="Install the app on your phone" background="bg-sky-500"/>
                  <GuideBox icon={<CameraIndoorTwoToneIcon sx={{ width: "50%", height: "50%", color: "#ccFFFFFF" }} />} text="Place the phone in a safe position" background="bg-blue-500" />
                  <GuideBox icon={<PlayCircleFilledWhiteTwoToneIcon sx={{ width: "50%", height: "50%", color: "#eeFFFFFF" }} />} text="Start the app and activate the alarm" background="bg-indigo-500" />
                  <GuideBox icon={<FeedbackTwoToneIcon sx={{ width: "50%", height: "50%", color: "#ccFFFFFF" }} />} text="Receive a message if an intruder is detected" background="bg-violet-500" />
    
                </div>
    
    
           </section>
    
    
    
              { /** Section 0 end **/}
    
    
    
    
            { /** Section 1 start **/}
    
    <div className="px-6 mx-auto bg-blue-200">
    
    
    <section className="text-gray-800 text-center lg:text-left">
          
      <div className="py-12 md:px-12">
        <div className="container mx-auto xl:px-32">
          <div className="grid lg:grid-cols-2 items-center">
            <div className="md:mt-12 lg:mt-0 mb-12 lg:mb-0">
              <div className="relative block rounded-lg shadow-lg px-6 pt-12 pb-6 md:px-12 lg:-mr-14 backgroundBlurredAppStoreContainer" >
                <h2 className="text-3xl font-bold mb-6">Download and get started right now!</h2>
                <p className="text-gray-500 mb-6">
                  No fees or subscriptions. No credit card required. No hidden costs. Download the app and start using it right away.
                </p>
    
                <div className="grid md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 gap-x-6">
    
    
                 <GooglePlayDownloadButton />
    
    
                </div>
              </div>
            </div>
            <div className="md:mb-12 lg:mb-0">
              <img src={process.env.PUBLIC_URL+"./examplescreenshot.png"}
                className="w-full rounded-lg shadow-lg rotate-lg-6 object-contain w-80 rotate-12" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    
    
    </div>
    
    
    
    { /** Section 1 end **/}
    
    <section className="mb-32 text-gray-800 bg-white">
          <div className='flex bg-white-500'>
            <div className='flex-none w-30 '>
              <img src="https://mdbootstrap.com/img/new/ecommerce/vertical/004.jpg"  />
            </div>
    
    
            
            <div className='grow flex flex-col'>
             
              
              <div className='mt-12 text-3xl my-0 md:4text-xl font-thin leading-tighter tracking-tighter aos-init aos-animate'>
                Protect What Matters Most
              </div>
              <div className='mx-10 my-10 mb-10 text-xl'>
                Experience peace of mind with our affordable and reliable motion detection app powered by AI technology.
              </div>
              <div className='grid grid-cols-2 gap-2'>
    
                <CheckmarkItem text="Detects motion quickly and accurately using AI technology." />
    
                <CheckmarkItem text="Alerts users in real-time when motion is detected, allowing them to take action quickly." />
              
                <CheckmarkItem text="Can differentiate between people, dogs, cars, and other objects, providing more specific information about what's happening." />
     
                <CheckmarkItem text="Low cost compared to other security systems" />
    
                <CheckmarkItem text="Easy to set up and use, with a user-friendly interface." />
    
                <CheckmarkItem text="Can be accessed remotely, giving users peace of mind when away from home or the office." />

                <CheckmarkItem text="Can be used for a variety of purposes, including home security, office security, and even for monitoring pets." />
    
                <CheckmarkItem text="Provides an affordable and effective way to keep people and property safe and secure." />
    
    
    
              </div>
              <div className=' mt-20 flex items-center justify-center'>
    
    
              <GooglePlayDownloadButton />  
    
    
    
              </div>
              { /** 
              
    
              **/}
            </div>
    
          </div>
    
    
    
    
    </section>
    
    
    
    
    
    
    { /** New navbar test end start*/}
    </div>
              </div>
            </div>
    { /** New navbar test end end*/}
            </div>
          )
    
}