import React from 'react';
import './DeviceListItem.css'
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Settings from '@material-ui/icons/Settings';
import Avatar from '@material-ui/core/Avatar';
import { red, green, orange, grey } from '@material-ui/core/colors';
import Badge from '@material-ui/core/Badge';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import DeviceConfiguration from './DeviceConfiguration';
import ListItem from '@material-ui/core/ListItem';


function getModalStyle() {
  const top = 0
  const left = 0

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },

  greenDot: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
  },
  orangeDot: {
    color: theme.palette.getContrastText(orange[500]),
    backgroundColor: orange[500],
  },
  redDot: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
  },
  greyDot: {
    color: theme.palette.getContrastText(grey[500]),
    backgroundColor: grey[500],
    sizes: "25px 54px",
  },

  orange: {
    color: theme.palette.getContrastText(orange[500]),
    backgroundColor: orange[500],
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  green: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  red: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    width: theme.spacing(3),
    height: theme.spacing(3),
  },  
  grey: {
    color: theme.palette.getContrastText(grey[500]),
    backgroundColor: grey[500],
    width: theme.spacing(3),
    height: theme.spacing(3),
  },  
  paper: {
    position: 'absolute',
    width: 600,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));


const StyledBadgeGreen = withStyles((theme) => ({
  badge: {
    backgroundColor: green[500],
    color: green[500],
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const StyledBadgeOrange = withStyles((theme) => ({
  badge: {
    backgroundColor: orange[500],
    color: orange[500],
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const StyledBadgeRed = withStyles((theme) => ({
  badge: {
    backgroundColor: red[500],
    color: red[500],
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const StyledBadgeGrey = withStyles((theme) => ({
  badge: {
    backgroundColor: grey[500],
    color: grey[500],
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);


export default function DeviceListItem(props) {


  let selectDevice = (e) => {
    e.stopPropagation();
    props.selectDeviceOnClick(props.deviceConfiguration);
  }


  let armAlarm = (e) => {
    e.stopPropagation();
    props.startAlarmOnClick(props.deviceConfiguration);
}

  let disarmAlarm = (e) => {
    e.stopPropagation();
    props.stopAlarmOnClick(props.deviceConfiguration); 
  }

  

  let renderArmDisarmButton = () => {
    let documentPath = props.deviceConfiguration.documentPath;
    let deviceState = null;
    for(var i = 0; i < props.deviceStates.length; i++) {
      //console.log("")
      if(props.deviceStates[i].deviceConfigurationDocumentPath===documentPath){
        deviceState = (props.deviceStates[i]);
        break;
      }
    }

    if (deviceState&&deviceState.uiState){
        switch(deviceState.uiState) {
            case "UNARMED":
            case "STANDBY":  
                return(
                    <Button variant="outlined" onClick={armAlarm}>
                        Arm
                    </Button>
                );
            case "READY_ARMED":
            case "ARMING_COUNTDOWN": 
                return(
                    <Button variant="outlined" onClick={disarmAlarm}>
                        Disarm
                    </Button>
                );
            default:
                return (
                    <Button variant="outlined" disabled>
                        Offline
                    </Button>
                )
        }
    }

  }

  let getDeviceStatusMessage = (deviceStates, deviceConfiguration) => {
    let documentPath = deviceConfiguration.documentPath;
    let deviceState = null;
    for(var i = 0; i < deviceStates.length; i++) {
      //console.log("")
      if(deviceStates[i].deviceConfigurationDocumentPath===documentPath){
        deviceState = (deviceStates[i]);
      }
    }
    if(deviceState&&deviceState.uiState){
      switch(deviceState.uiState) {
          case "UNARMED":
          case "STANDBY":  
            return "Unarmed";
          case "UPLOAD_BUSY_INDICATOR":
          case "COUNT_DOWN_TIME_BETWEEN_TRIGGERS":
            return "Object detected"
          case "READY_ARMED":
            return "Watching"
          case "ARMING_COUNTDOWN":
            return "Starting"
          case "OFFLINE":
            return "Offline"
          default:
            return "Unknown";
        }
      }else{
        return "Unknown";
      }
  }

  let getStyledBadge = (deviceStates, deviceConfiguration) => {

      let documentPath = deviceConfiguration.documentPath;
      let deviceState = null;
      for(var i = 0; i < deviceStates.length; i++) {
        if(deviceStates[i].deviceConfigurationDocumentPath===documentPath){
          deviceState = (deviceStates[i]);
        }

    }
    if(deviceState&&deviceState.uiState){
      switch(deviceState.uiState) {
          case "UNARMED":
          case "STANDBY":  
            return(
              <StyledBadgeGreen
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    variant="dot"
                  >
                    <Avatar style={{ height: '50px', width: 'auto' }} alt="Device image" src={process.env.PUBLIC_URL+"./phonestub.png"} variant="square" className={classes.greenDot} />
                </StyledBadgeGreen>
            )
           
          case "READY_ARMED":
            return(
              <StyledBadgeRed
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    variant="dot"
                  >
                    <Avatar style={{ height: '50px', width: 'auto' }} alt="Device image" src={process.env.PUBLIC_URL+"./phonestub.png"} variant="square" className={classes.redDot} />
                </StyledBadgeRed>
            )
            // code block
          case "ARMING_COUNTDOWN":
            return(
              <StyledBadgeOrange
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    variant="dot"
                  >
                    <Avatar style={{ height: '50px', width: 'auto' }} alt="Device image" src={process.env.PUBLIC_URL+"./phonestub.png"} variant="square" className={classes.orangeDot} />
                </StyledBadgeOrange>
            )
          case "UPLOAD_BUSY_INDICATOR":
          case "COUNT_DOWN_TIME_BETWEEN_TRIGGERS":
            return(
              <StyledBadgeRed
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    variant="dot"
                  >
                    <Avatar style={{ height: '50px', width: 'auto' }} alt="Device image" src={process.env.PUBLIC_URL+"./phonestub.png"} variant="square" className={classes.redDot} />
                </StyledBadgeRed>
            )
           
          case "OFFLINE":
            return(
              <StyledBadgeGrey
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    variant="dot"
                  >
                    <Avatar style={{ height: '50px', width: 'auto' }} alt="Device image" src={process.env.PUBLIC_URL+"./phonestub.png"} variant="square" className={classes.greyDot} />
                </StyledBadgeGrey>
            )
          default:
            // code block
        }
      }else{
        return(
          <StyledBadgeGrey
                overlap="circular"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant="dot"
              >
                <Avatar style={{ height: '50px', width: 'auto' }} alt="Device image" sx={{ width: 4, height: 4 }} src={process.env.PUBLIC_URL+"./phonestub.png"} variant="square" className={classes.greyDot} />
            </StyledBadgeGrey>
        )
      }
      
  }




  let openDeviceConfiguration = (e) => {
    e.stopPropagation();
    props.openDeviceConfigurationOnClick(props.deviceConfiguration);
  }

  const classes = useStyles();

  
  return (

    <ListItem
              key={props.deviceConfiguration.deviceName}
              role={undefined}
              dense
              button
              className={classes.listItem}
            >

        <div className={"DeviceListItem2Container"  + (props.deviceConfiguration.documentPath===props.selectedDeviceConfigurationDocumentPath ? " deviceListSelected":"")} onClick={selectDevice}>
          <div className="deviceImageContainer">
          {
              getStyledBadge(props.deviceStates, props.deviceConfiguration)
          }
          </div>
          <div className="deviceNameStateContainer">
              <div className="deviceName">
                  {props.deviceConfiguration.deviceName}
              </div>
              <div className="deviceUiStatusText">
                  {getDeviceStatusMessage(props.deviceStates, props.deviceConfiguration)}
              </div>
          </div>
        <div className="deviceButton">
            {renderArmDisarmButton()}
        </div>
        <div className="deviceConfiguration">
            <IconButton onClick={openDeviceConfiguration}>
              <Settings />
            </IconButton>

        </div>
        <div className={"deviceSelected"  + (props.deviceConfiguration.documentPath===props.selectedDeviceConfigurationDocumentPath ? " deviceListSelectedArrow":"")}>
           
        </div>     
      </div>

  </ListItem>
  )

}