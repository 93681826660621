import React from 'react';
import DeviceListItem2 from './DeviceListItem2';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function DeviceList(props) {

  const classes = useStyles();

  /*let deviceConfigurations =[];
  if(props.deviceConfigurations){
    deviceConfigurations = props.deviceConfigurations;
  }*/

  let deviceConfigurations = [];
  if(props && props.deviceConfigurations){
    deviceConfigurations = props.deviceConfigurations;
  }


  return (
  
    <div className="DeviceListContainer">


   {
   deviceConfigurations.map((deviceConfiguration) => (
        <div>

            <DeviceListItem2 key={deviceConfiguration.documentPath} 
                            selectDeviceOnClick={props.selectDeviceOnClick} 
                            setDeviceConfigurationOnClick={props.setDeviceConfigurationOnClick} 
                            deviceConfiguration={deviceConfiguration} 
                            startAlarmOnClick={props.startAlarmOnClick}
                            takePictureOnClick={props.takePictureOnClick}
                            stopAlarmOnClick={props.stopAlarmOnClick}
                            deviceStates={props.deviceStates}
                            openDeviceConfigurationOnClick={props.openDeviceConfigurationOnClick}
                            selectedDeviceConfiguration={props.selectedDeviceConfiguration}
                            selectedDeviceConfigurationDocumentPath={props.selectedDeviceConfigurationDocumentPath}/>
                            <Divider />
        </div>
))
   }

    </div>
  )

}