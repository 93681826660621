
import React, {Component} from 'react'
// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import 'firebase/storage';
import ReactDOM from 'react-dom'
import './index.css'
import ResponsiveDrawer from './ResponsiveDrawer';
import LandingPage from './LandingPage';
import CommandPanel from './CommandPanel';
import SimpleModal from './SimpleModal';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import {getDevices, getImages, deleteImages, deleteDeviceConfiguration, deleteUser, logoutAllDevicesAndUsers} from "./controllers/DeleteAccountController"
import AccountModal from './AccountModal';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const config = {
  apiKey: "AIzaSyB6VDAf4O49FoHOdazrkJZ9tTgvdWvow5g",//process.env.REACT_APP_API_KEY,
  authDomain: "motion-detector-ai.firebaseapp.com",//process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: "https://motion-detector-ai.firebaseio.com",//process.env.REACT_APP_DATABASE_URL,
  projectId:  "motion-detector-ai",//process.env.REACT_APP_PROJECT_ID,
  storageBucket: "motion-detector-ai.appspot.com",//process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: ""//process.env.REACT_APP_MESSAGING_SENDER_ID,
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#212936",
    },
    secondary: {
      main: "#36668d",
    },
  },
});



const cloudFunctionServerUrl = "https://us-central1-motion-detector-ai.cloudfunctions.net";

const pollingInterval = 1000*60*10;
const pollingTimeout = 1000*60*31; //If the last timestamp of the device is more than 30 minutes old, consider it offline

class App extends Component {

  

 
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    //this.state = { counter: 0 };
    firebase.initializeApp(config);
    this.state = {user:null, token:null, isLoggedIn:false, deviceConfigurations: [], deviceStates: [], selectedDeviceConfiguration:null, selectedDeviceState:null}
    //this.getDeviceConfigurations();
    //this.selectDeviceOnClick = this.selectDeviceOnClick.bind(this)
    this.selectedDeviceConfiguration = {}
    this.selectedDeviceState = {}
    this.serverTimestamp = new Date().getTime();
  }


  getDeviceConfigurations(userUid){
    var db = firebase.firestore();
    db.collection("deviceConfigurations")
      .where("userUid", "==", userUid)
      .onSnapshot((querySnapshot) =>  {
        let deviceConfigurationsFireBase = [];
        let count = 0;
        querySnapshot.forEach((doc) => {
          var data = doc.data();  
          if(count===0){

            this.setState({selectedDeviceConfigurationDocumentPath: data.documentPath, selectedDeviceConfiguration: data});
            this.getDeviceImages(data);
          }
          //data.selectDevice = () => this.selectDeviceOnClick(data);
  //        data.openDeviceConfiguration = () => this.openDeviceConfigurationOnClick(data);
          //data.setDeviceConfiguration = this.setDeviceConfiguration;
          deviceConfigurationsFireBase.push(data);
          count++;
      });
      //console.table(deviceConfigurationsFireBase);
      this.setState({deviceConfigurations: deviceConfigurationsFireBase});
      
      this.getDeviceStates();
    });

  }

  getDeviceStates(){
    var db = firebase.firestore();
    db.collection("deviceState")
      .onSnapshot((querySnapshot) =>  {
        let deviceStateFireBase = [];
        let count = 0;
          querySnapshot.forEach((doc) =>{
            var data = doc.data();  
            deviceStateFireBase.push(data);
            count++;
      });
      this.setState({deviceStates: deviceStateFireBase});
    });
  }

  
  /** Saves the device configuration to the database */
  setDeviceConfiguration(deviceConfiguration){
    console.log("MVA setDeviceConfiguration cn "+deviceConfiguration.documentPath);
    if(deviceConfiguration&&deviceConfiguration.documentPath){
      var db = firebase.firestore();
      var cityRef = db.collection('deviceConfigurations').doc(deviceConfiguration.documentPath);
      cityRef.set(deviceConfiguration, { merge: true });
      console.log(JSON.stringify(deviceConfiguration));
    }
  }

  async deleteDeviceConfiguration(deviceConfiguration){
    if(window.confirm("Delete "+deviceConfiguration.deviceName+"? This operation can take a while and cannot be undone.")){
      const images = await getImages(deviceConfiguration);
      for(let j=0; j<images.length; j++){
        const imageMarkedReference = images[j].imageMarkedReference;
        const imageDocumentPath = images[j].documentPath;
        await deleteImages(imageDocumentPath, imageMarkedReference);
      }
      try{
        await deleteDeviceConfiguration(deviceConfiguration.documentPath);
      }catch(error){
        console.log("Error deleting device configuration: "+error);
      }
      this.closeDeviceConfigurationModal();
    }

  }


  selectDeviceOnClick(deviceConfiguration){
      console.log("MVA selectDeviceOnClick() DeviceConfiguration "+deviceConfiguration.documentPath);
      this.setState({selectedDeviceConfigurationDocumentPath: deviceConfiguration.documentPath, selectedDeviceConfiguration:deviceConfiguration});
      this.getDeviceImages(deviceConfiguration);
  }

  updateDeviceStatePollingTimestamp(){
    console.log("updateDeviceStatePollingTimestamp");
    var url = cloudFunctionServerUrl+"/getTimeStamp";
    let xhr = new XMLHttpRequest()
    xhr.timeout = 10000
    xhr.addEventListener('load', () => {
      
      console.log("Timestamp from server:" +JSON.stringify(JSON.parse(xhr.responseText))+" - xhr.response: "+xhr.status+" "+xhr.statusText)
      try{
        this.serverTimestamp = Number(JSON.parse(xhr.responseText).timestamp);
        this._updateAllDeviceStates(this.serverTimestamp);
        //console.log("Interval running" + JSON.stringify(this.state.deviceStates));
        //If the device state polling timestamp has not been updated within the last 30 minutes, set the device state to offline...
          setInterval(this._updateDeviceStatePollingTimestampIntervalMethod.bind(this), pollingInterval);
      }catch{
        console.log("Could not start timer - something went wrong fetching the timestamp");
      }      

    })
    xhr.onerror = () => {
      console.log("Error - could not start timer. Server not responding with timestamp");
    }
    xhr.open('GET', url)
    xhr.send()
  }



  _updateDeviceStatePollingTimestampIntervalMethod(){
    //Start by fetching the server timestamp
      console.log("Fetch...")
      //Add a timer here, that runs 5 seconds after startup and then runs every 15 minutes
      var url = cloudFunctionServerUrl+"/getTimeStamp";
      let xhr = new XMLHttpRequest()
      xhr.timeout = 10000
      xhr.addEventListener('load', () => {
        console.log("Timestamp from server:" +JSON.stringify(JSON.parse(xhr.responseText))+" - xhr.response: "+xhr.status+" "+xhr.statusText)
        if(xhr.status===200){
          this.serverTimestamp = Number(JSON.parse(xhr.responseText).timestamp);
          this._updateAllDeviceStates(this.serverTimestamp);
        }
      });
      xhr.onerror = () => {
        console.log("Error - could not check timer");
      }
      xhr.open('GET', url)
      xhr.send()
  }

  _updateAllDeviceStates(serverTimestamp){
    var db = firebase.firestore();
    if(this.state && this.state.deviceStates){
      for (var i = 0; i < this.state.deviceStates.length; i++) {
        var deviceState = this.state.deviceStates[i];
        if(deviceState && deviceState.pollingTimestamp){
          console.log("Device timestamp: "+deviceState.pollingTimestamp.toMillis());
          console.log("Server timestmap: "+this.serverTimestamp);
          if(deviceState.uiState!=="OFFLINE" && serverTimestamp>(deviceState.pollingTimestamp.toMillis()+pollingTimeout)){
            //Change the device state of this device...
            console.log("DEVICE IS OFFLINE! "+deviceState.deviceConfigurationDocumentPath);
            deviceState.uiState = "OFFLINE";
            db.collection("deviceState").doc(deviceState.deviceConfigurationDocumentPath).set(deviceState)
          }
        }
      }
    }
  }



  openDeviceConfigurationOnClick(deviceConfiguration){
  }

  getImagePromise(imageUrl){
    
    var storage = firebase.storage();
    var storageRef = storage.ref();
    //var starsRef = storageRef.child('images/kB9iavyXhtZCFywOHai02phnNQQ2/30D32178F7C76E2390F59F04E37210F1A68485884D85EF15EA03065686D61903.png');
    var starsRef = storageRef.child(imageUrl);
    return starsRef.getDownloadURL()

    /*var starsRef = storageRef.child('images/stars.jpg');
    starsRef.getDownloadURL().then(function(url) {
      // Insert url into an <img> tag to "download"
    })*/
  }



  getDeviceImages(deviceConfiguration){
    var db = firebase.firestore();
    //var storage = firebase.storage();
    //var storageRef = storage.ref();
    //db.collection("images").where("userUid", "==", this.state.uid).where("deviceConfiguration.documentPath", "==", this.state.selectedDeviceConfigurationDocumentPath)
    //db.collection("images").where("deviceConfiguration.documentPath", "==", this.state.selectedDeviceConfigurationDocumentPath)
    db.collection("images").where("deviceConfiguration.documentPath", "==", deviceConfiguration.documentPath).orderBy("date", "desc")
    //db.collection("images").where("userUid", "==", this.state.uid)
      .onSnapshot((querySnapshot) =>  {
        let deviceConfigurationsFireBase = [];
        let imagePromises = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          data.documentPath = doc.id;
          deviceConfigurationsFireBase.push(data);
          imagePromises.push(this.getImagePromise(doc.data().imageMarkedReference));
        });
        Promise.all(imagePromises).then(values => {
          var i;
          for (i = 0; i < deviceConfigurationsFireBase.length; i++) {
            deviceConfigurationsFireBase[i].imageDownloadUrl = values[i];
          }
          this.setState({images: deviceConfigurationsFireBase}) 
        }).catch(error => {
          console.log("Error fetching images: "+error);
        });

    }).bind(this);
  }

  deleteImageOnClick(imageDocumentPath, imageMarkedReference){
    var storage = firebase.storage();
    var storageRef = storage.ref();
    var imageStorageRef = storageRef.child(imageMarkedReference);
    imageStorageRef.delete().then(() => {
    }).catch((error) => {
      console.error("Error removing image from storage: ", error);
    });


    var db = firebase.firestore();
    console.log(imageDocumentPath);
    let imageRef = db.collection("images").doc(imageDocumentPath);
    imageRef.delete().then(() => {
    }).catch((error) => {
        console.error("Error removing image: ", error);
    });
  }

  loginWithGoogle(){
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider).then(result => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      /*this.token = result.credential.accessToken;
      // The signed-in user info.
      this.user = result.user;*/
      console.log("MVA TEST");
      console.log("MVA index.js - user: "+JSON.stringify(result.user));
     
      this.setState({user:result.user, 
                    uid: result.user.uid,
                    token:result.credential.accessToken, 
                    displayName: result.displayName,
                    avatar: result.user.avatar,
                    isLoggedIn:true});
      this.getDeviceConfigurations(result.user.uid);
      this.updateDeviceStatePollingTimestamp();   
      this.createAccountIfNotExists(result.user);    
      // ...
    }).catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      var email = error.email;
      var credential = error.credential;
      console.log(error);
    });
  }

  createAccountIfNotExists(user){
    var db = firebase.firestore();
    var userRef = db.collection('users').doc(user.uid);
    var user = {
        email: user.email,
        isLoggedIn: true,
        loggedIn: true,
        uid: user.uid,
        userId: 1
    }
    userRef.set(user, { merge: true });
  }
 
async deleteAccount(){
    if( window.confirm("Are you absolutely sure you want to delete your account? ALL data will be deleted and cannnot be restored")){
      const userUid = this.state.user.uid;
      const user = this.state.user;
      let deviceConfigs = await this.getDevicesAsync(userUid);
      for(let i=0; i<deviceConfigs.length; i++){
        const images = await getImages(deviceConfigs[i]);
        for(let j=0; j<images.length; j++){
          const imageMarkedReference = images[j].imageMarkedReference;
          const imageDocumentPath = images[j].documentPath;
          await deleteImages(imageDocumentPath, imageMarkedReference);
        }
        try{
            await deleteDeviceConfiguration(deviceConfigs[i].documentPath);
        }catch(error){
          console.log("Error deleting device configuration: "+error);
        }
      }
      await logoutAllDevicesAndUsers(userUid);
      this.logoutWithGoogle(); 
    };
  }

  async getDevicesAsync(userUid){
    //console.log("MVA Delete account");
    const deviceConfigs = await getDevices(userUid);
    //console.log("MVA Delete: device configs: "+JSON.stringify(deviceConfigs));
    return deviceConfigs;
  }



  logoutWithGoogle(){
    firebase.auth().signOut().then(result =>{
        this.setState({user:null, 
          token:null, 
          isLoggedIn:false});
    }).catch(function(error){
        console.log("Error logging out");
    });
  }

  openDeviceConfigurationSettings(){
  }

  imageOnClick(imageUrl){
    console.log("imageOnClick "+imageUrl);
  }

  takePicture(deviceConfiguration){
    let url = cloudFunctionServerUrl+"/sendCommand?alarmCommand=3&documentId=null&userUid="+deviceConfiguration.userUid+"&deviceConfigurationDocumentPath="+deviceConfiguration.documentPath+"&firebaseMessageToken="+deviceConfiguration.fireBaseMessageToken
    if(window.location.hostname === "localhost"){
      url = "http://localhost:5001/motion-detector-ai/us-central1/sendCommand?alarmCommand=3&documentId=null&userUid="+deviceConfiguration.userUid+"&deviceConfigurationDocumentPath="+deviceConfiguration.documentPath+"&firebaseMessageToken="+deviceConfiguration.fireBaseMessageToken
    }
    console.log("takePicture url: "+url);
    this.getData(url);
  }

  startAlarm(deviceConfiguration){
    //let url = "https://us-central1-motion-detector-ai.cloudfunctions.net/sendCommand?alarmCommand=1documentId=null&&userUid="+deviceConfiguration.userUid+"&deviceConfigurationDocumentPath="+deviceConfiguration.documentPath+"&firebaseMessageToken="+deviceConfiguration.fireBaseMessageToken
    
    let url = cloudFunctionServerUrl+"/sendCommand?alarmCommand=1&documentId=null&userUid="+deviceConfiguration.userUid+"&deviceConfigurationDocumentPath="+deviceConfiguration.documentPath+"&firebaseMessageToken="+deviceConfiguration.fireBaseMessageToken
    if(window.location.hostname === "localhost"){
        url = "http://localhost:5001/motion-detector-ai/us-central1/sendCommand?alarmCommand=1&documentId=null&userUid="+deviceConfiguration.userUid+"&deviceConfigurationDocumentPath="+deviceConfiguration.documentPath+"&firebaseMessageToken="+deviceConfiguration.fireBaseMessageToken
    }
    //url = "https://us-central1-motion-detector-ai.cloudfunctions.net/sendCommand?alarmCommand=1&userUid=kB9iavyXhtZCFywOHai02phnNQQ2&deviceConfigurationDocumentPath=kB9iavyXhtZCFywOHai02phnNQQ20dda24d1-f6bf-46c5-a1f1-58a80acf6451&firebaseMessageToken=cVm3aL7YQLmz4R1rzF0D5Z:APA91bHiRqxDwgII66ML2iRWITLvrX5fkv80u_giBLMNJlgPImjp-VdQh2GuqQPtbwYRZVz56HA9C0zpVVtc4FohmMzK38Q-NTSeirzqqsgVrVTGDO5PG1wqLRNqF85_zLm7oL3DxRKY&documentId=kB9iavyXhtZCFywOHai02phnNQQ2";
    console.log("startalarm url: "+url);
    this.getData(url);
  }

  startAllAlarmsOnClick(){
    for (const deviceState of this.state.deviceStates){
      if(deviceState.uiState==='UNARMED'){
        for (var i = 0; i < this.state.deviceConfigurations.length; i++) {
          if(this.state.deviceConfigurations[i].documentPath===deviceState.deviceConfigurationDocumentPath){
            this.startAlarm(this.state.deviceConfigurations[i])
            break;
          }
        }
      }
    }
  }

  stopAllAlarmsOnClick(){
    for (const deviceState of this.state.deviceStates){
      if(deviceState.uiState!=='UNARMED' && deviceState.uiState!=='STANDBY' && deviceState.uiState!=='OFFLINE'){
        for (var i = 0; i < this.state.deviceConfigurations.length; i++) {
          if(this.state.deviceConfigurations[i].documentPath===deviceState.deviceConfigurationDocumentPath){
            this.stopAlarm(this.state.deviceConfigurations[i])
            break;
          }
        }
      }
    }
  }

  stopAlarm(deviceConfiguration){
    let url = cloudFunctionServerUrl+"/sendCommand?alarmCommand=2&documentId=null&userUid="+deviceConfiguration.userUid+"&deviceConfigurationDocumentPath="+deviceConfiguration.documentPath+"&firebaseMessageToken="+deviceConfiguration.fireBaseMessageToken
    if(window.location.hostname === "localhost"){
      url = "http://localhost:5001/motion-detector-ai/us-central1/sendCommand?alarmCommand=2&documentId=null&userUid="+deviceConfiguration.userUid+"&deviceConfigurationDocumentPath="+deviceConfiguration.documentPath+"&firebaseMessageToken="+deviceConfiguration.fireBaseMessageToken
    }
    console.log("stopAlarm url: "+url);
    this.getData(url);
  }

  updateDeviceState(deviceConfiguration){
    let url = cloudFunctionServerUrl+"/sendCommand?alarmCommand=4&documentId=null&&userUid="+deviceConfiguration.userUid+"&deviceConfigurationDocumentPath="+deviceConfiguration.documentPath+"&firebaseMessageToken="+deviceConfiguration.fireBaseMessageToken
    if(window.location.hostname === "localhost"){
      url = "http://localhost:5001/motion-detector-ai/us-central1/sendCommand?alarmCommand=4&documentId=null&userUid="+deviceConfiguration.userUid+"&deviceConfigurationDocumentPath="+deviceConfiguration.documentPath+"&firebaseMessageToken="+deviceConfiguration.fireBaseMessageToken
    }
    console.log("updateDeviceState url: "+url);
    this.getData(url);
  }

  openDeviceConfigurationModal(deviceConfiguration){
    this.selectDeviceOnClick(deviceConfiguration);
    this.setState({open: true});
  }

  closeDeviceConfigurationModal(){
    this.setState({open: false});
  }

  showAccountModal(){
    this.setState({accountModalOpen: true});
  }

  closeAccountModal(){
    this.setState({accountModalOpen: false});
  }


  getData(url) {
    let xhr = new XMLHttpRequest()
    xhr.addEventListener('load', () => {
      console.log(xhr.responseText)
    })
    xhr.open('GET', url)
    xhr.send()
  }



  renderCommandPanel(){
    return(
      <div style={{position:"absolute", left:0, top:0, backgroundColor:"red"}}>
      <CommandPanel                   
          startAlarmOnClick={this.startAlarm.bind(this)}
          takePictureOnClick={this.takePicture.bind(this)}
          stopAlarmOnClick={this.stopAlarm.bind(this)}
          setDeviceConfigurationOnClick={this.setDeviceConfiguration.bind(this)}
          deviceStates={this.state.deviceStates}
          deviceConfiguration={this.state.deviceConfigurations}
          selectedDeviceConfiguration={this.state.selectedDeviceConfiguration}
          selectedDeviceConfigurationDocumentPath={this.state.selectedDeviceConfigurationDocumentPath}
      />
      </div>
    )
  }


  renderAuthorized(){
    return(
    
    
    
    <>
      <ThemeProvider theme={theme}>
          <div>
          
            <ResponsiveDrawer 
                startAlarmOnClick={this.startAlarm.bind(this)} 
                stopAlarmOnClick={this.stopAlarm.bind(this)} 
                openDeviceConfigurationOnClick={this.openDeviceConfigurationModal.bind(this)} 
                takePictureOnClick={this.takePicture.bind(this)}
                selectDeviceOnClick={this.selectDeviceOnClick.bind(this)} 
                setDeviceConfigurationOnClick={this.setDeviceConfiguration.bind(this)} 
                deviceConfigurationSettingsOnClick={this.openDeviceConfigurationSettings.bind(this)} 
                images={this.state.images} 
                imageOnClick={this.imageOnClick.bind(this)} 
                deviceConfigurations={this.state.deviceConfigurations} 
                deleteImageOnClick={this.deleteImageOnClick.bind(this)}
                deviceStates={this.state.deviceStates}
                selectedDeviceConfiguration={this.state.selectedDeviceConfiguration}
                startAllAlarmsOnClick={this.startAllAlarmsOnClick.bind(this)}
                stopAllAlarmsOnClick={this.stopAllAlarmsOnClick.bind(this)}
                logout={this.logoutWithGoogle.bind(this)}
                user={this.state.user}
                selectedDeviceConfigurationDocumentPath={this.state.selectedDeviceConfigurationDocumentPath}
                showAccountModal={this.showAccountModal.bind(this)}
                deleteAccount={this.deleteAccount.bind(this)} />
        
          </div>

          <SimpleModal open={this.state.open }
            startAlarmOnClick={this.startAlarm.bind(this)}
            takePictureOnClick={this.takePicture.bind(this)}
            stopAlarmOnClick={this.stopAlarm.bind(this)}
            setDeviceConfigurationOnClick={this.setDeviceConfiguration.bind(this)}
            deviceStates={this.state.deviceStates}
            deviceConfiguration={this.state.selectedDeviceConfiguration}
            selectedDeviceConfigurationDocumentPath={this.state.selectedDeviceConfigurationDocumentPath}
            deleteDeviceConfigurationOnClick={this.deleteDeviceConfiguration.bind(this)}
            closeModal={this.closeDeviceConfigurationModal.bind(this)}
          />
          <AccountModal 
            user={this.state.user}
            open={this.state.accountModalOpen}
            deleteAccount={this.deleteAccount.bind(this)} 
            closeModal={this.closeAccountModal.bind(this) }
        />

         </ThemeProvider>

      </>
      )

  }



  render() {
    let view;
    if (!this.state.isLoggedIn) {
      view = <LandingPage loginWithGoogle={this.loginWithGoogle.bind(this)} />;
    } else {
      view = this.renderAuthorized();
    }

    return (
      <div className="App">
        {view}
      </div>
    )
  }
}

ReactDOM.render(<App />, document.getElementById('root'))