import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import Modal from '@material-ui/core/Modal';
import SurveillanceImage from './SurveillanceImage';
import { render } from '@testing-library/react';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
  },
  media: {
    height: 0,
    paddingTop: '96.25%', // 16:9
  },
  
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));


export default function SurveillanceImageGridItem(props) {
  const classes = useStyles();
  let imageData = {};

  let handleClick = () => {
    props.imageOnClick(imageData);
  }

  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);



  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if(props && props.image){
   imageData = props.image;
  };


  const body = (
    <div style={modalStyle} className={classes.paper}>
      <SurveillanceImage detectedObjects = {props.detectedObjects} imageDocumentPath={props.imageDocumentPath} imageMarkedReference={props.imageMarkedReference} deleteImageOnClick={props.deleteImageOnClick} imageUrl={imageData}/>
    </div>
  );

  let deleteImg = (e) => {
    e.stopPropagation();
    props.deleteImageOnClick(props.imageDocumentPath, props.imageMarkedReference);
  }


 
  return (
      <div>
      <Card className={classes.root}>
    
        <CardMedia
        
          onClick={handleOpen} 
          className={classes.media}
          image={imageData}
          title=""
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            Detected items: <br/>
            <span>{props.detectedObjects.map(tfobject => tfobject.tfId).join(", ")}</span>
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <IconButton aria-label="Delete" onClick={deleteImg}>
            <DeleteIcon />
          </IconButton>
        </CardActions>
        
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      </div>
  );
}