import firebase from "firebase/app";

export function getImages(deviceConfiguration){
    var db = firebase.firestore();
    let images = [];
    return new Promise((resolve, reject) => {
        db.collection("images").where("deviceConfiguration.documentPath", "==", deviceConfiguration.documentPath)
        .onSnapshot((querySnapshot) =>  {
        querySnapshot.forEach((doc) => {
            var data = doc.data(); 
            data.documentPath = doc.id;
            images.push(data);
        });
        resolve(images)
      });
      
    });
}

export function getDevices(userUid) {
    var db = firebase.firestore();
    let deviceConfigurationsFireBase = [];
    return new Promise((resolve, reject) => {
        db.collection("deviceConfigurations")
        .where("userUid", "==", userUid)
        .onSnapshot((querySnapshot) =>  {
          querySnapshot.forEach((doc) => {
            var data = doc.data();  
            deviceConfigurationsFireBase.push(data);
          });
          resolve(deviceConfigurationsFireBase)

      });

    });

}


export function deleteImageFileFromStorage(imageMarkedReference){
  console.log("MVA deleteImageFileFromStorage - imageMarkedReference: "+imageMarkedReference);
  const storage = firebase.storage();
  const storageRef = storage.ref();
  const imageStorageRef = storageRef.child(imageMarkedReference);
  return imageStorageRef.delete();
}

export function deleteImageReferenceFromFirestore(imageDocumentPath){
  console.log("MVA deleteImageReferenceFromFirestore - imageDocumentPath: "+imageDocumentPath);
  const db = firebase.firestore();
  let imageRef = db.collection("images").doc(imageDocumentPath); 
  return imageRef.delete();
}


export async function deleteImages(imageDocumentPath, imageMarkedReference){
  try {
      await deleteImageFileFromStorage(imageMarkedReference);
  }catch(error){
      console.error("MVA deleteImages - error: "+error);
  }

  try {
    await deleteImageReferenceFromFirestore(imageDocumentPath);
  }catch(error){
      console.error("MVA deleteImages - error: "+error);
  }
}

  //Use this function to delete a single device configuration
  export async function deleteDeviceConfiguration(deviceDocumentPath){
    console.log("MVA deleteDeviceConfiguration "+deviceDocumentPath);
    const db = firebase.firestore();
    let ref = db.collection("deviceConfigurations").doc(deviceDocumentPath); 
    return ref.delete();
  }

  /** Calling this method will log all devices out */
  export function logoutAllDevicesAndUsers(userUid){
    var db = firebase.firestore();
    var userRef = db.collection('users').doc(userUid);
    var user = {
        email: "deleted",
        isLoggedIn: false,
        loggedIn: false
    }
    return userRef.set(user, { merge: true });
    
  }

