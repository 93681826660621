import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Modal from '@material-ui/core/Modal';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SurveillanceImagesGrid from './SurveillanceImagesGrid';
import DeviceList from './DeviceList';
import GlobalCommandPanel from './GlobalCommandPanel';
import Button from '@material-ui/core/Button';


const drawerWidth = 500;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}


function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  let deviceList;

  if(props.deviceConfigurations) {
    deviceList = <DeviceList 
                  selectDeviceOnClick={props.selectDeviceOnClick} 
                  setDeviceConfigurationOnClick={props.setDeviceConfigurationOnClick} 
                  deviceConfigurations={props.deviceConfigurations} 
                  startAlarmOnClick={props.startAlarmOnClick}
                  takePictureOnClick={props.takePictureOnClick}
                  stopAlarmOnClick={props.stopAlarmOnClick}
                  deviceStates={props.deviceStates}
                  openDeviceConfigurationOnClick={props.openDeviceConfigurationOnClick}
                  selectedDeviceConfiguration={props.selectedDeviceConfiguration}
                  selectedDeviceConfigurationDocumentPath={props.selectedDeviceConfigurationDocumentPath} />
  } else {
    deviceList = null
  }
  
  const drawer = (
    <div>
      <Divider />
        <GlobalCommandPanel 
                user={props.user}
                logout={props.logout}
                deleteAccount={props.deleteAccount}
                stopAllAlarmsOnClick={props.stopAllAlarmsOnClick} 
                showAccountModal={props.showAccountModal}
                startAllAlarmsOnClick={props.startAllAlarmsOnClick}/>
      <Divider />
        {deviceList}
    </div>
  );

  let getDeviceName = (props) => {
    if(props && props.selectedDeviceConfiguration && props.selectedDeviceConfiguration.deviceName){
      return props.selectedDeviceConfiguration.deviceName;
    }else{
      return "No name"
    }
}

  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {getDeviceName(props)}
          </Typography>
          <Button variant="outlined" color="inherit" style={{marginLeft: "auto"}} onClick={props.logout}>Log out</Button>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
            <SurveillanceImagesGrid        
                selectDeviceOnClick={props.selectDeviceOnClick} 
                setDeviceConfigurationOnClick={props.setDeviceConfigurationOnClick} 
                deviceConfigurations={props.deviceConfigurations} 
                startAlarmOnClick={props.startAlarmOnClick}
                takePictureOnClick={props.takePictureOnClick}
                stopAlarmOnClick={props.stopAlarmOnClick}
                deviceStates={props.deviceStates}
                deleteImageOnClick={props.deleteImageOnClick}
                selectedDeviceState={props.selectedDeviceState}
                selectedDeviceConfiguration={props.selectedDeviceConfiguration}
                selectedDeviceConfigurationDocumentPath={props.selectedDeviceConfigurationDocumentPath}            
                images={props.images}
                openDeviceConfigurationOnClick={props.openDeviceConfigurationOnClick} />
      </main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
