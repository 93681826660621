import React from "react";

export default function GuideBox(props) {

    return (
        <div className={"rounded-lg  w-48 h-48 mr-10 "+props.background+" flex shadow-2x"} >
                 <div className="m-auto">
                    <div className="drop-shadow-lg">
                        {props.icon}
                    </div>
                    <div className="text-white px-1 drop-shadow-md">
                        {props.text}
                    </div>
                  </div>
                </div>
  );
}