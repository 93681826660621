import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Settings from '@material-ui/icons/Settings';


const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 20,
    },
    pos: {
      marginBottom: 6,
    },
  });

export default function CommandPanel(props) {

    const classes = useStyles();

    let deviceConfigurations = [];
    if(props && props.deviceConfigurations){
        deviceConfigurations = props.deviceConfigurations;
    }

    const renderStartStopButton = (deviceStates, selectedDeviceConfiguration) => {
        if(deviceStates && selectedDeviceConfiguration){
            let documentPath = selectedDeviceConfiguration.documentPath;
            let deviceState = null;
            for(var i = 0; i < deviceStates.length; i++) {
                if(deviceStates[i].deviceConfigurationDocumentPath===documentPath){
                    deviceState = (deviceStates[i]);
                }

            }
            if(deviceState&&deviceState.uiState){
                switch(deviceState.uiState) {
                    case "UNARMED":
                    case "STANDBY":    
                        return(
                            <Button variant="outlined" onClick={startAlarm} style={{marginRight:"10px"}}>
                                Start
                            </Button>
                        )
                    case "READY_ARMED":
                    case "ARMING_COUNTDOWN":    
                        return (
                            <Button variant="outlined" onClick={stopAlarm} style={{marginRight:"10px"}}>
                                Stop
                            </Button>
                        )
                    default:
                        return (
                            <Button variant="outlined" disabled style={{marginRight:"10px"}}>
                                Offline
                            </Button>
                        )
                }
            }
        }else{
            console.log("Null");
        }
    }   

    const renderTakePictureButton = (deviceStates, selectedDeviceConfiguration) => {
        if(deviceStates && selectedDeviceConfiguration){
            let documentPath = selectedDeviceConfiguration.documentPath;
            let deviceState = null;
            for(var i = 0; i < deviceStates.length; i++) {
                if(deviceStates[i].deviceConfigurationDocumentPath===documentPath){
                    deviceState = (deviceStates[i]);
                }
            }
            if(deviceState&&deviceState.uiState){
                switch(deviceState.uiState) {
                    case "UNARMED":
                    case "READY_ARMED":
                    return(
                            <Button variant="outlined" onClick={takePicture}>
                                Take picture
                            </Button>
                        )
                    default:
                        return (
                            <Button variant="outlined" disabled>
                                Take picture
                            </Button>
                        )
                    }
                    
                }
            }else{
                console.log("null 2");
            }
        }

    let takePicture = (e) => {
        e.stopPropagation();
        props.takePictureOnClick(props.deviceConfiguration);
    }

    let startAlarm = (e) => {
        e.stopPropagation();
        props.startAlarmOnClick(props.deviceConfiguration);
    }

    let stopAlarm = (e) => {
        e.stopPropagation();
        props.stopAlarmOnClick(props.deviceConfiguration);
    }

    let openDeviceConfiguration = (e) => {
        e.stopPropagation();
        props.openDeviceConfigurationOnClick(props.deviceConfiguration);
      }

  return (
  <>    
  <div className="CommandPanelContainer">
   
   <Card className={classes.root} variant="outlined" >
        <CardActions>
            <div className="deviceConfiguration">
                <IconButton onClick={openDeviceConfiguration}>
                    <Settings />
                </IconButton>
                {
                renderStartStopButton(props.deviceStates, props.deviceConfiguration)
               
            }
            {
                renderTakePictureButton(props.deviceStates, props.deviceConfiguration)
            }
            </div>
            
        </CardActions>
    </Card>

    </div>
   
    </>

  )

}